import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import { endOfMonth, endOfWeek, format, startOfDay, startOfMonth, startOfWeek } from 'date-fns';

import { DesktopTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SelectChangeEvent } from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import GlobalStyles from '@mui/material/GlobalStyles';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import {
    ATTACHMENT_DATE_RANGE,
    ATTACHMENT_TYPE,
    getAttachmentType,
    getPeriodType,
    getReportType,
    PERIOD,
    RestaurantReportPreferences,
    REPORT_TYPE,
    IScheduledReports,
    WEEK_DAYS,
} from '@/services/utils/schedulerReport_helper';
import ScheduledReportsService from '@/services/scheduledReports';
import { useRestaurantContext } from '@/contexts/restaurant';
import { useTranslation } from '@/hooks/translations';
import { PrimaryButton } from '@/components/common';
import { getLocaleLang } from '@/components/TimeRange/Form/utils';
import { emailRegex } from '@/constants/regexes';

const customTimePickerStyles = (
    <GlobalStyles
        styles={{
            '.MuiList-root.MuiList-padding.MuiMultiSectionDigitalClockSection-root:after': {
                height: 'auto',
            },
        }}
    />
);

export const initialRestaurantPreferences: RestaurantReportPreferences = {
    reportType: REPORT_TYPE.ORDER_VIEW,
    dateRange: ATTACHMENT_DATE_RANGE.TODAY,
    period: PERIOD.DAILY,
    attachmentType: ATTACHMENT_TYPE.NONE,
    reportTime: {
        hour: new Date().getHours().toString(),
        minute: new Date().getMinutes().toString(),
        day: WEEK_DAYS.SUNDAY,
    },
    cc: [],
    fileName: '',
};

const RestaurantScheduledReportEditor = ({ modalOpen, handleModalClose, editData, refresh }: IScheduledReports) => {
    const scheduledReportsService = ScheduledReportsService.getInstance();
    const { t } = useTranslation('common');
    const router = useRouter();
    const lang: string = router.query.lang as string;
    const { restaurant } = useRestaurantContext();
    const { enqueueSnackbar } = useSnackbar();
    const [dateStrings, setDateStrings] = useState<string[]>([]);
    const [errorText, setErrorText] = useState('');
    const [reportTime, setReportTime] = useState<Date | null>(new Date());
    const [loading, setLoading] = useState<boolean>(false);

    const [reportPreferences, setReportPreferences] =
        useState<RestaurantReportPreferences>(initialRestaurantPreferences);

    useEffect(() => {
        const date = new Date();
        const yesterday = new Date().setDate(new Date().getDate() - 1);

        setDateStrings([
            format(yesterday, 'LLLL dd', { locale: getLocaleLang(lang) }),
            format(startOfDay(date), 'LLLL dd'),
            `${format(startOfMonth(date), 'LLLL dd')}-${format(endOfMonth(date), 'LLLL dd', {
                locale: getLocaleLang(lang),
            })}`,
            `${format(startOfWeek(date, { weekStartsOn: 1 }), 'LLLL dd', { locale: getLocaleLang(lang) })}-${format(
                endOfWeek(date),
                'LLLL dd',
                { locale: getLocaleLang(lang) },
            )}`,
        ]);
    }, []);

    useEffect(() => {
        if (!editData) return;

        const convertedDate = new Date();
        convertedDate.setHours(parseInt(editData?.payload?.report_time?.hour, 10));
        convertedDate.setMinutes(parseInt(editData?.payload?.report_time?.minute, 10));
        const xDateHour = parseInt(editData?.payload?.report_time?.hour, 10);
        const xDateMinute = parseInt(editData?.payload?.report_time?.minute, 10);

        setReportTime(
            new Date(
                convertedDate.getFullYear(),
                convertedDate.getMonth(),
                convertedDate.getDate(),
                xDateHour,
                xDateMinute,
            ),
        );

        setReportPreferences({
            ...reportPreferences,
            reportType: getReportType(editData.payload.report_type) || REPORT_TYPE.ORDER_VIEW,
            dateRange: editData.payload.date_range || ATTACHMENT_DATE_RANGE.TODAY,
            period: editData.payload.period || PERIOD.DAILY,
            attachmentType: editData.payload.attachment_type || ATTACHMENT_TYPE.NONE,
            reportTime: {
                hour: xDateHour.toString(),
                minute: xDateMinute.toString(),
                day: editData?.payload?.report_time?.day,
            },
            cc: editData.payload.cc,
        });
    }, [editData]);

    useEffect(() => {
        if (!modalOpen) {
            setReportPreferences(initialRestaurantPreferences);
        }
    }, [modalOpen]);

    const handleSave = () => {
        setLoading(true);
        scheduledReportsService
            .createRestaurantReport(
                {
                    ...reportPreferences,
                    dateRange: reportPreferences.dateRange,
                    timezoneId: restaurant?.timezone || '',
                },
                restaurant?.id || '',
            )
            .then(() => {
                refresh();
                enqueueSnackbar(t('Report created successfully'), { variant: 'success' });
            })
            .catch((err) => {
                enqueueSnackbar(`${err}`, { variant: 'error' });
            })
            .finally(() => {
                handleModalClose();
                setLoading(false);
            });
    };

    const handleUpdate = () => {
        setLoading(true);

        scheduledReportsService
            .updateRestaurantReport(
                {
                    ...reportPreferences,
                    timezoneId: restaurant?.timezone || '',
                },
                restaurant?.id || '',
                editData.id,
            )
            .then(() => {
                refresh();
                enqueueSnackbar(t('Report updated successfully'), { variant: 'success' });
            })
            .catch((err) => {
                enqueueSnackbar(`${err}`, { variant: 'error' });
            })
            .finally(() => {
                handleModalClose();
                setLoading(false);
            });
    };
    const handleTimeChange = (newValue: Date | null) => {
        if (newValue) {
            setReportTime(newValue);
            setReportPreferences({
                ...reportPreferences,
                reportTime: { hour: newValue.getHours().toString(), minute: newValue.getMinutes().toString() },
            });
        }
    };
    const handleReportTypeChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        setReportPreferences({ ...reportPreferences, reportType: getReportType(e.target.value) });
    const handleDateRangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(e.target.value);
        setReportPreferences({ ...reportPreferences, dateRange: e.target.value });
    };
    const handlePeriodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === PERIOD.DAILY) {
            const newReportPreferences = { ...reportPreferences };
            delete newReportPreferences.reportTime;
            setReportPreferences({ ...newReportPreferences, period: getPeriodType(e.target.value) });
        } else {
            setReportPreferences({
                ...reportPreferences,
                period: getPeriodType(e.target.value),
                reportTime: {
                    hour: new Date().getHours().toString(),
                    minute: new Date().getMinutes().toString(),
                    day: WEEK_DAYS.SUNDAY,
                },
            });
        }
    };
    const handleDayOfWeekChange = (event: SelectChangeEvent<WEEK_DAYS>) => {
        setReportPreferences({
            ...reportPreferences,
            reportTime: {
                hour: reportPreferences.reportTime?.hour || new Date().getHours().toString(),
                minute: reportPreferences.reportTime?.minute || new Date().getMinutes().toString(),
                day: (event.target.value as WEEK_DAYS) || WEEK_DAYS.SUNDAY,
            },
        });
    };

    return (
        <Dialog open={modalOpen} onClose={handleModalClose} fullWidth>
            <DialogTitle sx={{ fontSize: '30px' }}>{editData ? t('Edit Report') : t('Create Report')}</DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <FormControl>
                            <FormLabel
                                sx={{
                                    color: '#616475',
                                    fontSize: '1rem',
                                }}
                            >
                                {t('Report Type')}
                            </FormLabel>
                            <RadioGroup
                                defaultValue={REPORT_TYPE.TRANSACTION_VIEW}
                                name="type-radio-group"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                                onChange={handleReportTypeChange}
                                value={reportPreferences.reportType}
                            >
                                <FormControlLabel
                                    value={REPORT_TYPE.ORDER_VIEW}
                                    control={<Radio />}
                                    label={t('Order View')}
                                    sx={{
                                        '& .MuiFormControlLabel-label': {
                                            fontSize: '.9rem',
                                        },
                                    }}
                                />
                                <FormControlLabel
                                    value={REPORT_TYPE.TRANSACTION_VIEW}
                                    control={<Radio />}
                                    label={t('Transaction View')}
                                    sx={{
                                        '& .MuiFormControlLabel-label': {
                                            fontSize: '.9rem',
                                        },
                                    }}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl size="small" fullWidth>
                            <Typography
                                sx={{
                                    color: '#616475',
                                    fontSize: '1rem',
                                }}
                            >
                                {t('Attachment Type')}
                            </Typography>
                            <Select
                                value={reportPreferences.attachmentType}
                                onChange={(event: SelectChangeEvent) => {
                                    setReportPreferences({
                                        ...reportPreferences,
                                        attachmentType: getAttachmentType(event.target.value),
                                    });
                                }}
                            >
                                <MenuItem value={ATTACHMENT_TYPE.NONE}>{t('none')}</MenuItem>
                                <MenuItem value={ATTACHMENT_TYPE.CSV}>{t('CSV')}</MenuItem>
                                <MenuItem value={ATTACHMENT_TYPE.XLSX}>{t('XLSX')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <FormLabel
                                sx={{
                                    color: '#616475',
                                    fontSize: '1rem',
                                }}
                            >
                                {t('Attachment Date Range')}
                            </FormLabel>
                            <RadioGroup
                                defaultValue={ATTACHMENT_DATE_RANGE.CURRENT_MONTH}
                                name="period-radio-group"
                                onChange={handleDateRangeChange}
                                value={reportPreferences.dateRange}
                            >
                                <Grid container spacing={0}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControlLabel
                                            value={ATTACHMENT_DATE_RANGE.YESTERDAY}
                                            control={<Radio />}
                                            label={t('Yesterday')}
                                            sx={{
                                                '& .MuiFormControlLabel-label': {
                                                    fontSize: '.9rem',
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} alignSelf="center">
                                        {dateStrings[0]}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControlLabel
                                            value={ATTACHMENT_DATE_RANGE.TODAY}
                                            control={<Radio />}
                                            label={t('Today')}
                                            sx={{
                                                '& .MuiFormControlLabel-label': {
                                                    fontSize: '.9rem',
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} alignSelf="center">
                                        {dateStrings[1]}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControlLabel
                                            value={ATTACHMENT_DATE_RANGE.CURRENT_MONTH}
                                            control={<Radio />}
                                            label={t('Current Month')}
                                            sx={{
                                                '& .MuiFormControlLabel-label': {
                                                    fontSize: '.9rem',
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} alignSelf="center">
                                        {dateStrings[2]}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControlLabel
                                            value={ATTACHMENT_DATE_RANGE.CURRENT_WEEK}
                                            control={<Radio />}
                                            label={t('Current Week')}
                                            sx={{
                                                '& .MuiFormControlLabel-label': {
                                                    fontSize: '.9rem',
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} alignSelf="center">
                                        {dateStrings[3]}
                                    </Grid>
                                </Grid>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <FormLabel
                                sx={{
                                    color: '#616475',
                                }}
                            >
                                {t('Other Recipients (cc)')}
                            </FormLabel>
                            <Autocomplete
                                sx={{
                                    margin: '1rem 0',
                                }}
                                multiple
                                fullWidth
                                id="tags-filled"
                                options={[]}
                                onChange={(event, newVals) => {
                                    if (!emailRegex.test(newVals[newVals.length - 1])) {
                                        setErrorText(t('Invalid email ID'));
                                    } else {
                                        setErrorText('');
                                    }

                                    if (!emailRegex.test(newVals[newVals.length - 1])) {
                                        newVals.pop();
                                    }

                                    setReportPreferences({ ...reportPreferences, cc: newVals });
                                }}
                                onBlur={() => setErrorText('')}
                                freeSolo
                                renderInput={(params) => {
                                    return (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder={t('Email address of recipient')}
                                            helperText={
                                                errorText ||
                                                t(
                                                    'Add multiple email IDs separated by commas for more than one recipient',
                                                )
                                            }
                                            size="small"
                                            error={!!errorText}
                                        />
                                    );
                                }}
                                value={[...reportPreferences.cc]}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <FormLabel
                                sx={{
                                    color: '#616475',
                                    fontSize: '1rem',
                                }}
                            >
                                {t('Frequency')}
                            </FormLabel>
                            <RadioGroup
                                defaultValue={PERIOD.DAILY}
                                name="period-radio-group"
                                onChange={handlePeriodChange}
                                value={reportPreferences.period}
                            >
                                <Grid container spacing={0}>
                                    <Grid item xs={6}>
                                        <FormControlLabel
                                            value={PERIOD.DAILY}
                                            control={<Radio />}
                                            label={t('Daily')}
                                            sx={{
                                                '& .MuiFormControlLabel-label': {
                                                    fontSize: '.9rem',
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            {customTimePickerStyles}
                                            <DesktopTimePicker
                                                value={reportTime}
                                                onChange={handleTimeChange}
                                                slotProps={{
                                                    textField: {
                                                        size: 'small',
                                                        fullWidth: true,
                                                    },
                                                }}
                                                disabled={reportPreferences.period !== PERIOD.DAILY}
                                            />
                                        </LocalizationProvider>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <FormControlLabel
                                            value={PERIOD.WEEKLY}
                                            control={<Radio />}
                                            label={t('Weekly')}
                                            sx={{
                                                '& .MuiFormControlLabel-label': {
                                                    fontSize: '.9rem',
                                                },
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DesktopTimePicker
                                                value={reportTime}
                                                onChange={handleTimeChange}
                                                slotProps={{ textField: { size: 'small', fullWidth: true } }}
                                                disabled={reportPreferences.period !== PERIOD.WEEKLY}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl size="small" fullWidth>
                                            <Select
                                                value={reportPreferences.reportTime?.day}
                                                onChange={handleDayOfWeekChange}
                                                disabled={reportPreferences.period !== PERIOD.WEEKLY}
                                            >
                                                <MenuItem value={WEEK_DAYS.SUNDAY}>{t('Sunday')}</MenuItem>
                                                <MenuItem value={WEEK_DAYS.MONDAY}>{t('Monday')}</MenuItem>
                                                <MenuItem value={WEEK_DAYS.TUESDAY}>{t('Tuesday')}</MenuItem>
                                                <MenuItem value={WEEK_DAYS.WEDNESDAY}>{t('Wednesday')}</MenuItem>
                                                <MenuItem value={WEEK_DAYS.THURSDAY}>{t('Thursday')}</MenuItem>
                                                <MenuItem value={WEEK_DAYS.FRIDAY}>{t('Friday')}</MenuItem>
                                                <MenuItem value={WEEK_DAYS.SATURDAY}>{t('Saturday')}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControlLabel
                                            value={PERIOD.MONTHLY}
                                            control={<Radio />}
                                            label={t('Monthly')}
                                            sx={{
                                                '& .MuiFormControlLabel-label': {
                                                    fontSize: '.9rem',
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DesktopTimePicker
                                                value={reportTime}
                                                onChange={handleTimeChange}
                                                slotProps={{ textField: { size: 'small', fullWidth: true } }}
                                                disabled={reportPreferences.period !== PERIOD.MONTHLY}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            value={PERIOD.HOURLY}
                                            control={<Radio />}
                                            label={t('Hourly')}
                                            sx={{
                                                '& .MuiFormControlLabel-label': {
                                                    fontSize: '.9rem',
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: ' 1rem 1.6rem 1.6rem 1.6rem',
                    backgroundColor: '#F8F8F8',
                }}
            >
                <PrimaryButton
                    id="cancel-btn"
                    styles={{ backgroundColor: '#C4C4C4' }}
                    color="grey"
                    onClick={handleModalClose}
                    text={t('Cancel')}
                />
                <PrimaryButton
                    id="action-btn"
                    onClick={editData ? handleUpdate : handleSave}
                    text={editData ? t('Update') : t('Create')}
                    disabled={loading}
                />
            </DialogActions>
        </Dialog>
    );
};

export default RestaurantScheduledReportEditor;
